import React from "react";

import { cn } from "~/utils/cn";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
  DialogHeader,
} from "../dialog";
import { Button, ButtonKind, ButtonStandaloneKind } from "../../button";
import { ComposableDialogProps } from "./composable-dialog.types";
import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog";
import { ButtonIcon } from "../../button-icon";

export const ComposableDialog: React.FC<ComposableDialogProps> = ({
  isOpen,
  title,
  onCancel,
  onOpenChange,
  children,
  className,
  headerClassName,
  contentClassName,
  successButtonProps,
  standaloneButtonProps,
  cancelButtonProps,
  description,
  hideCancelButton = false,
  bottomLeftAction,
  rightHeaderElement,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn("flex flex-col items-center gap-0 rounded-xl", className)}
      >
        <DialogHeader className={cn("w-full justify-between", headerClassName)}>
          <div className="flex items-center gap-1">
            <DialogClose asChild data-testid="close-dialog-button">
              <ButtonIcon icon="fa-solid fa-xmark" />
            </DialogClose>

            <div className="flex flex-col tracking-tighter">
              <DialogTitle className="text-lg/6 font-medium text-neutral-primary">
                {title}
              </DialogTitle>
              {!!description && (
                <DialogDescription className="text-sm text-neutral-secondary">
                  {description}
                </DialogDescription>
              )}
            </div>
          </div>

          {/* Right-aligned header element */}
          {rightHeaderElement && <>{rightHeaderElement}</>}
        </DialogHeader>

        <div
          className={cn(
            "max-h-[70vh] w-full overflow-y-auto p-4",
            contentClassName,
          )}
        >
          {children}
        </div>
        <DialogFooter className="w-full">
          <div className="flex w-full items-center justify-between">
            {bottomLeftAction && (
              <button
                onClick={bottomLeftAction.onClick}
                className="font-inter ml-4 flex items-center gap-2 text-sm font-medium leading-6 tracking-[-0.6px] text-[#0021F8]"
              >
                {bottomLeftAction.label}
                {bottomLeftAction.icon && (
                  <i className={bottomLeftAction.icon} />
                )}
              </button>
            )}
            <div className="ml-auto flex gap-2">
              {standaloneButtonProps && (
                <Button
                  isStandalone={true}
                  kind={ButtonStandaloneKind.brand}
                  onClick={standaloneButtonProps?.onClick}
                  disabled={standaloneButtonProps?.disabled}
                >
                  {standaloneButtonProps?.label}
                </Button>
              )}
              {!hideCancelButton && (
                <DialogTrigger asChild>
                  <Button
                    kind={ButtonKind.secondary}
                    onClick={onCancel}
                    data-testid="dialog-cancel-button"
                    {...cancelButtonProps}
                  >
                    {cancelButtonProps?.label ? cancelButtonProps.label : "Cancel"}
                  </Button>
                </DialogTrigger>
              )}
              {successButtonProps && (
                <Button {...successButtonProps}>
                  {successButtonProps.label}
                </Button>
              )}
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
