import React from "react";

import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "../dialog";
import { Button, ButtonKind, ButtonWidth } from "../../button";
import { Body, BodySize, BodyType, Title, TitleSize } from "../../typography";
import { cn } from "~/utils/cn";
import { FeedbackDialogProps } from "./feedback-dialog.types";
import {
  DEFAULT_LABEL_CANCEL,
  DEFAULT_LABEL_CONFIRM,
} from "./feedback-dialog.contants";

export const FeedbackDialog: React.FC<FeedbackDialogProps> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  onOpenChange,
  icon,
  hasCancelButton,
  disabled,
  loading,
  ...props
}) => {
  if (!isOpen) {
    return null;
  }
  const labelConfirm = props.labelConfirm ?? DEFAULT_LABEL_CONFIRM;
  const labelCancel = props.labelCancel ?? DEFAULT_LABEL_CANCEL;

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className="flex flex-col items-center gap-0 rounded-xl p-6"
        aria-describedby={undefined}
      >
        <div className="flex size-[88px] items-center justify-center rounded-full bg-brand-primary-light-10">
          <i className={cn(icon, "text-[2.5rem] text-brand-primary-rest")} />
        </div>
        <div className="mt-4 flex flex-col items-center gap-1">
          <DialogTitle asChild>
            <Title
              className="text-base font-medium tracking-[-0.6px]"
              size={TitleSize.extraSmall}
            >
              {title}
            </Title>
          </DialogTitle>
          <Body size={BodySize.small} type={BodyType.muted}>
            {message}
          </Body>
        </div>
        <div className="mt-6 w-full space-y-2">
          <Button
            width={ButtonWidth.full}
            disabled={disabled ?? loading}
            loading={loading}
            onClick={onConfirm}
            aria-label={labelConfirm}
          >
            {labelConfirm}
          </Button>
          {hasCancelButton && (
            <DialogTrigger asChild>
              <Button
                width={ButtonWidth.full}
                kind={ButtonKind.secondary}
                onClick={onCancel}
                aria-label={labelCancel}
              >
                {labelCancel}
              </Button>
            </DialogTrigger>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
