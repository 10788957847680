export enum BodySize {
  xsmall = "xsmall",
  small = "small",
  medium = "medium",
  large = "large",
}

export enum BodyType {
  emphasys = "emphasys",
  basic = "basic",
  muted = "muted",
}

export enum TextAlign {
  left = "left",
  center = "center",
  end = "end",
}

export enum BodyColor {
  primary = "primary",
  secondary = "secondary",
}

export interface BodyProps {
  children: React.ReactNode;
  size?: BodySize;
  underline?: boolean;
  italic?: boolean;
  type?: BodyType;
  asChild?: boolean;
  center?: boolean;
  className?: string;
  textAlign?: TextAlign;
  style?: React.CSSProperties;
  color?: BodyColor;
}
