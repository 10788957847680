import { tv } from "tailwind-variants";
import { BodyColor, BodySize, BodyType, TextAlign } from "./body.types";

export const bodyClassName = tv({
  variants: {
    textAlign: {
      [TextAlign.left]: "w-full text-left",
      [TextAlign.center]: "w-full text-center",
      [TextAlign.end]: "w-full text-right",
    },
    size: {
      [BodySize.large]: "text-lg/8 tracking-tighter",
      [BodySize.medium]: "text-base tracking-[-0.7px]",
      [BodySize.small]: "text-sm/6 tracking-[-0.6px]",
      [BodySize.xsmall]: "text-xs tracking-[-0.6px]",
    },
    color: {
      [BodyColor.primary]: "text-neutral-primary",
      [BodyColor.secondary]: "text-neutral-secondary",
    },
    type: {
      [BodyType.emphasys]: "font-medium",
      [BodyType.basic]: "font-normal",
      [BodyType.muted]: "font-normal text-neutral-secondary",
    },
    underline: {
      true: "underline",
      false: "no-underline",
    },
    italic: {
      true: "italic",
      false: "",
    },
    center: { true: "text-center" },
  },
  defaultVariants: {
    size: BodySize.medium,
    type: BodyType.basic,
    underline: false,
    color: BodyColor.primary,
  },
});
