import React from "react";
import { Button, ButtonAlign, ButtonKind, ButtonSize } from "../default";
import { ButtonIcon } from "../../button-icon";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/scalis-components/core/dropdown-menu";
import { ActionButtonProps } from "./action-button.types";
import { cn } from "~/utils/cn";

export const ActionButton: React.FC<ActionButtonProps> = ({
  actionsItems,
  asButtonIcon,
}) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        {asButtonIcon ? (
          <ButtonIcon icon="fa-solid fa-ellipsis" />
        ) : (
          <Button
            className="w-8 p-1"
            size={ButtonSize.xs}
            kind={ButtonKind.secondary}
            align={ButtonAlign.center}
          >
            <i className="fa-solid fa-ellipsis text-icon-neutral-20" />
          </Button>
        )}
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align="end"
        className="min-w-[192px] rounded-xl bg-white p-2"
      >
        {actionsItems.map(item => {
          return (
            <DropdownMenuItem
              key={item.label}
              className="p-2 hover:cursor-pointer hover:bg-background-hover"
              onClick={() => item.onClick()}
            >
              <div className="flex items-center gap-2">
                <div className="flex h-4 w-4 items-center justify-center">
                  <i className={cn(item.icon, "text-neutral-primary")} />
                </div>
                <span className="text-sm font-normal text-neutral-primary">
                  {item.label}
                </span>
              </div>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
